/**
 * BlogArticle
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class BlogArticle
 * @param {object} props - Props
 */

import React from "react";
// import Parser from 'html-react-parser';

const BlogArticle = ({children}) => {

	return (
		<article className="blog-article">
			{children}
		</article>
	);
}

export default BlogArticle;
