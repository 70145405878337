import { useEffect } from 'react'


let channelsightScriptInserted = false;
export const eventuallyInsertChannelsightScript = () => {
  if (!channelsightScriptInserted) {
    console.log('Appending Channelsight script');
    channelsightScriptInserted = true;
    const channelsightScript = document.createElement('script');
    channelsightScript.id = `channelsight-script-injected`;
    channelsightScript.setAttribute('src', 
      `https://cscoreproweustor.blob.core.windows.net/widget/scripts/cswidget.loader.js`);
      channelsightScript.setAttribute('async', '');
      channelsightScript.setAttribute('defer', '');
    document.head.appendChild(channelsightScript);

    const removeListener = () => {
      console.log('Removing Channelsight script');
      const csScript = document.getElementById(`channelsight-script-injected`);
      if (csScript) {
        csScript.remove();
      }
      const csWidgetScript = document.getElementById(`cswidgetjs`);
      if (csWidgetScript) {
        csWidgetScript.remove();
      }
      channelsightScriptInserted = false;
      window.removeEventListener('cswidget-gatsby-route-change', removeListener);
    };
    window.addEventListener('cswidget-gatsby-route-change', removeListener);
  }
}

let wtbComponentId = 0;

const useWhereToBuy = () => {
  useEffect(() => {
    wtbComponentId = (wtbComponentId + 1) % 1000;
    eventuallyInsertChannelsightScript(wtbComponentId);
  }, []);

  return '699';
}

export default useWhereToBuy;