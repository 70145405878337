/**
 * ProductCard
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class ProductCard
 * @param {object} props - Props
 */

import React, { Fragment } from "react";
import Button from "../../atoms/button"
import Chip from "../../atoms/product-chip"
import Icon from "../../atoms/icon"
import useWhereToBuy from "../../hooks/use-where-to-buy";
import ReevooBadge from "../../components/reevoo/reevoo-badge"
import { Link } from "../../atoms/link"

const mainInfo = (
	className = 'product-card__main-info',
	isCollapsible,
	expanded = true,
	kspsExpandLabel,
	family,
	productName,
	productNameVerbose,
	keySellingPoint,
	compare,
	lightVersion
) => {

	function kspsToggle(e) {
		const trigger = e.target;
		const parent = trigger.closest(".product-card");
		parent.classList.toggle("expanded");
	}

	return <div className={className}>
		<div className="content">
			<div className="product-card__product-name">
				{
					family ?
						<div className="product-card__family">
							{family.title}
						</div>
						: null
				}
				<div className="product-card__name--verbose">
					{productNameVerbose}
				</div>
				<div className="product-card__name">
					{productName}
				</div>
			</div>
			{
				keySellingPoint?.length && compare !== true && lightVersion !== true ?
					<div className="product-card__key-selling-points">
						{/* {console.log(keySellingPoint)} */}
						<ul>
							{
								keySellingPoint.map((point, idx) => (
									<li key={idx}>
										<span className="icon"></span>{point}
									</li>
								))
							}
						</ul>
						{
							isCollapsible ?
								<div role="button" tabIndex={0} onClick={kspsToggle} onKeyDown={kspsToggle} className="product-card__key-selling-control">
									<span>
										{expanded === true ?
											kspsExpandLabel.expand //collapse key selling points panel
											: kspsExpandLabel.collapse //expand key selling points panel
										}
										<em></em>
									</span>
								</div>
								: null
						}
					</div>
					: null
			}
		</div>
	</div>
}

const ProductCard = (product) => {

	const {
		key_prod,
		productName,
		productNameVerbose,
		callToAction,
		chips,
		familiesIds,
		internal,
		lightVersion,
		triples,
		kspsExpandLabel,
		price,
		connectivity,
		wheretoBuy,
		compare,
		...others
	} = product;
	const images = product.primaryimage;
	const img = images.filter(image => image.imageType === '640')[0];
	const family = familiesIds[0]

	const whereToBuyAssetId = useWhereToBuy();

	let keySellingPoints = [];
	triples.map((t, idx) => (
		keySellingPoints[idx] = t.keySellingPoint
	))
	const isCollapsible = keySellingPoints?.length > 3 ? true : false;

	// console.log(product)
	console.log(keySellingPoints)
	return (
		<Fragment>
			<div className={`product-card ${isCollapsible === true ? 'product-card--isCollapsible' : ''}`} data-sku={productName}>

				<div className="product-card__content">
					<header className={`product-card__header ${chips ? 'product-card__header--has-chihps' : ''}`}>
						<div className="product-card__chips">
							{
								chips ?
									chips.map((chip, idx) => (
										<Chip
											label={chip.label}
											category={chip.category}
										/>
									))
									: null
							}
						</div>
						<ReevooBadge productId={key_prod} />
						{
							compare !== true ?
								<div className="product-card__compare">
									<Button
										className="compare"
										color="blue"
										link="/lavatrici--compare-overlay"
										label="Compare"
										type="compare"
									/>
								</div>
								: null
						}
					</header>
					<div className="product-card__image">
						<Link
							to={callToAction.link}
							title={productNameVerbose}
						>
							<img src={img.imageUrl} alt={productNameVerbose} />
						</Link>
					</div>
					{mainInfo('product-card__main-info product-card__main-info--default',
						isCollapsible,
						true,
						kspsExpandLabel,
						family,
						productName,
						productNameVerbose,
						keySellingPoints,
						compare,
						lightVersion
					)}
					{
						isCollapsible === true && lightVersion !== true ?
							mainInfo('product-card__main-info product-card__main-info--expanded',
								isCollapsible,
								false,
								kspsExpandLabel,
								family,
								productName,
								productNameVerbose,
								keySellingPoints,
								compare,
								lightVersion
							)
							: null
					}
					{
						connectivity && compare !== true && lightVersion !== true ?
							<div className="product-card__connectivity">
								<Icon
									color="blue"
									name="system-connectivity"
								/> {connectivity}
							</div>
							: null

					}
					{
						price && lightVersion !== true ?
							<Fragment>
								<div className="product-card__price">
									{
										price.old ?
											<span className="old">{price.old}</span>
											: null
									}
									{
										price.current ?
											<span className="current">{price.current}</span>
											: null
									}
								</div>

							</Fragment>
							: null
					}
					{
						compare === true && !price ?
							<div className=""></div> //needed for grid template in compare module!
							: null
					}
					<div className="product-card__buttons">
						{
							price?.storeLink && lightVersion !== true ?
								<Button
									className="secondary"
									color="blue"
									href={price.storeLink}
									label={price.storeCta}
								/>
								: null
						}

						{
							wheretoBuy === true && lightVersion !== true ?
								<Button
									className="secondary cswidget"
									color="white"
									label={'Tutte le opzioni di acquisto'}
									data-product-sku={key_prod}
									data-asset-id={whereToBuyAssetId}
								/>
								: null
						}

						<Button
							className="arrow _16"
							// color="gray"
							color="blue"
							href={callToAction.link}
							label={callToAction.label}
							type="arrow"
						/>
					</div>
				</div>


			</div>

		</Fragment>


	);
}


export default ProductCard;
