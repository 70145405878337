import React from "react"

import Layout from "../app/layouts/default"
import Seo from "../app/services/seo"
import { graphql, useStaticQuery } from 'gatsby'
import Breadcrumbs from "../app/components/breadcrumbs";
import CardArticle from "../app/components/card-article";
import BlogArticle from "../app/components/blog-article";
import BlogNavigation from "../app/components/blog-navigation";
import Hero from "../app/components/blog-hero";
import Helpers from "../app/helpers";
import RelatedProducts from "../app/modules/related-products";
import StickyButtons from "../app/components/sticky-buttons";

const BlogArticlePage = () => {
    const data = useStaticQuery(
        graphql`
                query {
                    allBlogJson {
                    edges {
                      node {
                        id
                      }
                    }
                    nodes {
                        lang
                      articles {
                        link
                        title
                        categories
                        date
                        description
                        image
                      }
                    }
                  }
                  allBabelProdotto(
        filter: {familiesIds: {}, primaryimage: {elemMatch: {imageType: {}}}, lineId: {eq: 777}}
      ) {
        edges {
          node {
            key_prod
            attributes {
              id
              category {
                id
                nameCategory
                ordCategory
                parentCategory
              }
              fieldName
              fieldType
              fieldValue
            }
            lineId
            id
            dimensions {
              a
              l
              p
            }
            otherImages {
              imageUrl
              imageType
            }
            familiesIds {
              title
              id
              triples {
                id
                image
                linkcall
                linkimg
                market_exception
                text
                titimg
                title
                video
              }
            }
            brand_id
            brandName
            productName
            primaryimage {
              imageType
              imageUrl
            }
            internal {
              description
              ignoreType
              mediaType
              content
            }
            triples {
              id
              image
              keySellingPoint
              linkcall
              linkimg
              market_exception
              text
              titimg
              title
              video
            }
            connectivity
          }
        }
      }
                }`
    );
    const { articles } = data.allBlogJson.nodes.find(n => n.lang === 'it_IT');

    let products = data.allBabelProdotto.edges;
    products = Helpers.productsDataManipulation(products, false);

    const breadcrumbs = [
        {
            link: '/',
            label: 'Home'
        },
        {
            link: '/blog',
            label: 'Blog'
        },
        {
            label: 'Come usare l\'asciugatrice per non stirare'
        }
    ];

    const shareTitle = 'Blog Article Page';
    const shareUrl = 'https://www.candy.it/it_IT/chiedi-a-candy/-/asset_publisher/6eeUAvIfXnpd/content/candy-trio-e-aquamatic-le-soluzioni-intelligenti-per-piccoli-spazi';
    return (
        <Layout headerActiveItem={2}>
            <Seo title="" />
            <StickyButtons
                socials={[
                    {
                        name: 'facebook',
                        link: `http://www.facebook.com/sharer.php?u=${shareUrl}`,

                    },
                    {
                        name: 'twitter',
                        link: `http://twitter.com/share?url=${shareUrl}`,

                    },
                    {
                        name: 'whatsapp',
                        link: `https://api.whatsapp.com/send?text=${shareTitle} ${shareUrl}`,

                    },
                    {
                        name: 'mail',
                        link: `mailto:?subject=${shareTitle}&body=${shareUrl}`,

                    },
                    {
                        name: 'link',
                        link: shareUrl,
                    },
                ]}
            />
            <div className="container">
                <Breadcrumbs homeLabel={'Home'} items={breadcrumbs} />
            </div>
            <Hero
                date="30/03/21"
                title="Come usare l'asciugatrice per non stirare"
                abstract="Come usare l’asciugatrice per non stirare: ecco qualche consiglio utile"
                image="https://www.candy-home.com/adapt-image/454387/Promozioni%20slider%20900x900?w=640&h=640&q=60&fm=webp&version=1.0&t=1630598541622"
            />
            <div className="container">
                <div className="page-blog-article">
                    <div className="blog-article-wrapper">
                        <div className="blog-article__container">
                            <BlogArticle>
                                <h2>
                                    Il ferro da stiro è il tuo incubo? <br />
                                    Niente paura, ecco come usare l’asciugatrice per non stirare i capi ed ottenere un
                                    risultato perfetto
                                </h2>
                                <p>Hai sempre sognato di avere i vestiti in ordine senza ricorrere al ferro da stiro? Il
                                    tuo sogno sta per diventare realtà. Se presterai attenzione a delle piccole
                                    accortezze, potrai <strong>usare la tua asciugatrice per non stirare i capi</strong>.
                                    Segui i nostri consigli e dimenticati le lunghe giornate dietro all’asse da stiro.
                                </p>
                                <h3>Come fare l’asciugatrice per evitare di stirare i capi</h3>
                                <p>
                                    L’asciugatrice è ormai di uso comune in tantissime famiglie e, soprattutto durante
                                    la stagione invernale, può essere una soluzione davvero comoda. L’asciugatrice ti
                                    permette di non dover più tenere in casa ingombranti stendini e capi umidi che
                                    ovviamente rendono l’ambiente freddo. Inoltre grazie alla vasta <a href="/">gamma di
                                        asciugatrici RapidÓ</a>, potrai risparmiare davvero molto tempo. Questo modello
                                    propone un set di ben 7 programmi rapidi che svolgeranno l’azione desiderata in meno
                                    di 90 minuti.
                                </p>
                                <p>
                                    Ma come fare per <strong>non stirare i capi dopo averli tirati fuori
                                        dall’oblò</strong>? Segui questi rapidi passaggi:
                                </p>
                                <ul>
                                    <li><strong>Non caricare eccessivamente l’asciugatrice</strong>: il sovraccarico
                                        del cestello impedisce ai capi di disperdere l’umidità e quindi di
                                        asciugarsi in modo omogeneo. Inoltre, riporre troppi abiti contribuirà a
                                        sgualcirli ulteriormente.
                                    </li>
                                    <li>Imposta il programma “funzione a vapore”: un’ottima soluzione a portata di
                                        mano per distendere i tessuti in modo naturale e non ricorrere al ferro da
                                        stiro.
                                    </li>
                                    <li><strong>Dividi i capi per tipo di tessuto</strong>: accorpa gli indumenti o
                                        la biancheria che richiedono stesso tempo e temperatura, questo ti
                                        permetterà di impostare il programma più adatto per il risultato atteso.
                                    </li>
                                    <li><strong>Inserisci gli indumenti con cura</strong>: non è necessario piegare
                                        i vestiti, tuttavia, riporli in modo ordinato nell’oblò può fare la
                                        differenza. Assicurati che le maniche di maglie e pantaloni non siano
                                        accartocciate e metti al contrario i capi con particolari decorazioni.
                                    </li>
                                </ul>
                                <img
                                    src="https://www.candy-home.com/adapt-image/424817/Efficiency_Tracking-IMG-900X900px?w=620&h=620&q=60&fm=jpg&version=1.0&t=1629374735341"
                                    alt="" />

                                <h3>Come funziona l’asciugatrice? Altri segreti per non stirare i capi</h3>
                                <p>
                                    Per ogni capo che si introduce nell’elettrodomestico, assicurati di <a href="/">impostare
                                        il programma di asciugatura più adatto</a>. Ricordati poi di sbattere molto bene gli
                                    indumenti e cerca di distendere le piccole pieghe che probabilmente si sono formate
                                    in lavatrice durante il lavaggio.
                                </p>
                                <p>
                                    Nel caso particolare delle <strong>lenzuola</strong>, l’asciugatrice va usata con
                                    particolare attenzione. Inseriscile da sole per far sì che non si arrotolino e
                                    toglile dopo circa 20/30 minuti per completare l’asciugatura sullo stendino.
                                </p>
                                <p>
                                    Un altro trucco poco conosciuto è quello del <strong>ghiaccio</strong>. Inserisci
                                    2/3 cubetti di ghiaccio prima di azionare il ciclo di asciugatura e lasciali agire
                                    per circa 10/15 minuti. Il vapore sprigionato dal ghiaccio in scioglimento farà il
                                    modo di distendere i tessuti già durante l’asciugatura. Non hai il ghiaccio a
                                    disposizione? Usa un piccolo panno o un calzino inumiditi.
                                </p>
                                <p>
                                    Infine, puoi inserire le palline di lana per l’asciugatrice per ottenere risultati
                                    migliori. Se riposte correttamente, il tempo di asciugatura può ridursi anche del
                                    25%, rendendo i tuoi <strong>capi molto più morbidi</strong>. Ne esistono di tutti i
                                    tipi e, per un risultato ancora più impeccabile, puoi anche <strong>profumare il
                                        bucato in asciugatrici</strong>, aggiungendoci qualche goccia di oli essenziali
                                    naturali.
                                </p>

                            </BlogArticle>
                        </div>
                        <div className="blog-article-wrapper__sidebar">
                            <h4 className="blog-article-wrapper__sidebar-title">Articoli Correlati</h4>
                            {/*<CardArticlesList articles={articles.slice(0, 3)} highlight={false} withControls={false}/>*/}
                            {
                                articles && articles.slice(0, 3).map(({ description, image, date, link, categories, title }, idx) => (
                                    <CardArticle
                                        key={idx}
                                        title={title}
                                        preTitle={categories}
                                        date={date}
                                        description={description}
                                        image={image}
                                        link={link}

                                        align="left"
                                    />
                                ))
                            }
                        </div>
                        <BlogNavigation
                            prev={{
                                title: "Come stirare veloce: consigli",
                                category: "guida all'utilizzo",
                                image: "https://www.candy-home.com/adapt-image/454387/Promozioni%20slider%20900x900?w=640&h=640&q=60&fm=webp&version=1.0&t=1630598541622",
                                link: '#'
                            }}
                            next={{
                                title: "I vantaggi delle lavatrici slim",
                                category: "guida all'utilizzo",
                                image: "https://www.candy-home.com/adapt-image/508333/Disinfettare%20scarpe.jpg?w=640&h=640&q=60&fm=webp&version=1.0&t=1631806948788",
                                link: '#'
                            }}
                            className="blog-article-wrapper__navigation"
                        />
                    </div>
                </div>
                <section className="pdp-related-products">
                    <h2>
                        Potrebbe interessarti anche
                    </h2>
                    <RelatedProducts
                        lightVersion
                        compareBtns={false}
                        products={products.slice(0, 4)}
                    />
                </section>
            </div>
        </Layout>
    )
}

export default BlogArticlePage;
