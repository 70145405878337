/**
 * BlogNavigationItem
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class BlogNavigationItem
 * @param {object} props - Props
 */

import React from "react";

const BlogNavigationItem = (
    {
        action = 'next',
        title,
        category,
        link,
        image
    }) => {

    return (
        <>
            <div
                className={`blog-navigation-item ${action === 'next' ? 'blog-navigation-item--next' : 'blog-navigation-item--prev'}`}>
                {action === 'prev' &&
                <img
                    className="blog-navigation-item__image"
                    src={image}
                    alt=""/>
                }
                <div className="blog-navigation-item__caption">
                    <p className="blog-navigation-item__category">{category}</p>
                    <p className="blog-navigation-item__title">{title}</p>
                    <a href={link}
                       className="blog-navigation-item__link">articolo {action === 'next' ? 'successivo' : 'precedente'}</a>
                </div>
                {action === 'next' &&
                <img
                    className="blog-navigation-item__image"
                    src={image}
                    alt=""/>
                }
            </div>
        </>
    );
}

export default BlogNavigationItem;
