/**
 * BlogNavigation
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class BlogNavigation
 * @param {object} props - Props
 */

import React from "react";
import BlogNavigationItem from "../blog-navigation-item";

const BlogNavigation = (
    {
        next,
        prev
    }
) => {

    return (
        <div className="blog-navigation">
            {prev && <BlogNavigationItem
                action="prev"
                title={prev.title}
                category={prev.category}
                image={prev.image}
                link={prev.link}
            />}
            {next && <BlogNavigationItem
                action="next"
                title={next.title}
                category={next.category}
                image={next.image}
                link={next.link}
            />}
        </div>
    );
}

export default BlogNavigation;
