import React, { useEffect, useRef } from 'react';
import { eventuallyInsertReevooScript } from '../../utility/reevoo';


const ReevooBadge = ({ productId }) => {
  const containerRef = useRef();

  useEffect(() => {
    const container = containerRef.current;
    if (container && productId) {
      // console.log('Create reevoo badge: ', productId);
      const badge = document.createElement('reevoo-badge');
      // badge.setAttribute('min-reviews', '0');
      badge.setAttribute('product-id', productId);
      badge.setAttribute('name', 'b_product_score_square_1');
      container.appendChild(badge);
      eventuallyInsertReevooScript('CDD-IT');
      return () => {
        container.removeChild(badge)
      };
    }
  }, [productId]);

  return (
    <div className="reevoo-badge-container">
      <div ref={containerRef}></div>
    </div>
  );
}

export default React.memo(ReevooBadge);