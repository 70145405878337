/**
 * BlogHero
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class BlogHero
 * @param {object} props - Props
 */

import React from "react";

class BlogHero extends React.Component {

	render() {
		const { title, abstract, date, image } = this.props;
		return (
			<div className="blog-hero">
				<div className="container">
					<div className="blog-hero__image" style={{
						backgroundImage: `url(${image})`
					}}>
					</div>
					<div className="blog-hero__content">
						<div className="inner">
							<div className="blog-hero__date">
								{date}
							</div>
							<div className="blog-hero__title">
								{title}
							</div>
							<div className="blog-hero__abstract">
								{abstract}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

}

export default BlogHero;
