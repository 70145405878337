/**
 * ProductChip
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class ProductChip
 * @param {object} props - Props
 */

import React from "react";

class ProductChip extends React.Component {

	render() {
		const { category, label, ...props } = this.props;
		return (
			<div className={`product-chip product-chip--${category}`} {...props}>
				<span className="product-chip__label">
					{label}
				</span>
			</div>
		);
	}

}

export default ProductChip;
